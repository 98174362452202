export const PHOTO_PRINT_LAYOUTS = {
    'Glossy': {
        '3R Wallet' : { // verify
            variantId: '42344623472697',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 15,
            minOrder: 20,
            isDoubleLayout: true
        },
        '3R': {
            variantId: '42344623505465',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 10,
            minOrder: 20
        },
        '4R': {
            variantId: '42344623538233',
            width: 4,
            height: 6,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 12,
            minOrder: 20
        },
        '5R': {
            variantId: '42344623571001',
            width: 5,
            height: 7,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 20,
            minOrder: 20
        },
        '6R': {
            variantId: '42344623603769',
            width: 6,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 24,
            minOrder: 20
        },
        '12RW (12x18)': {
            variantId: '42344623669305',
            width: 12,
            height: 18,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 230
        },
        '11R (11x14)': {
            variantId: '42344623702073',
            width: 11,
            height: 14,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 150
        },
        '10S (10x10)': {
            variantId: '42344623734841',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 90
        },
        '12R (12x16)': {
            variantId: '42344623767609',
            width: 12,
            height: 16,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 200
        },
        '8R': {
            variantId: '42344623800377',
            width: 8,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 70
        },
        '8S (8x8)': {
            variantId: '42344623865913',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 55
        },
        'Photo Wall Tile 5x5 in Black Frame': {
            variantId: '42344623898681',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in Black Frame': {
            variantId: '42344623931449',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in Black Frame': {
            variantId: '42344623964217',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        },
        'Photo Wall Tile 5x5 in White Frame': {
            variantId: '42344623996985',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in White Frame': {
            variantId: '42344624029753',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in White Frame': {
            variantId: '42344624062521',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        },
    },
    'Glazz-IT': {
        '8x10' : {
            variantId: '42443405819961',
            width: 8,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 245
        },
        '8x12' : {
            variantId: '42443405852729',
            width: 8,
            height: 12,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 295
        },
        '10x10' : {
            variantId: '42443405885497',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 320
        },
    },
    'Lustre / Matte': {
        '3R Wallet' : { // verify
            variantId: '42344622751801',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 15,
            minOrder: 20,
            isDoubleLayout: true
        },
        '3R': {
            variantId: '42344622784569',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 10,
            minOrder: 20
        },
        '4R': {
            variantId: '42344622817337',
            width: 4,
            height: 6,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 12,
            minOrder: 20
        },
        '5R': {
            variantId: '42344622850105',
            width: 5,
            height: 7,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 20,
            minOrder: 20
        },
        '6R': {
            variantId: '42344622915641',
            width: 6,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 24,
            minOrder: 20
        },
        '12RW (12x18)': {
            variantId: '42344622948409',
            width: 12,
            height: 18,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 230
        },
        '11R (11x14)': {
            variantId: '42344622981177',
            width: 11,
            height: 14,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 150
        },
        '8R': {
            variantId: '42344623013945',
            width: 8,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 70
        },
        '8S (8x8)': {
            variantId: '42344623046713',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 55
        },
        'Photo Wall Tile 5x5 in Black Frame': {
            variantId: '42344623079481',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in Black Frame': {
            variantId: '42344623112249',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in Black Frame': {
            variantId: '42344623145017',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        },
        'Photo Wall Tile 5x5 in White Frame': {
            variantId: '42344623177785',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in White Frame': {
            variantId: '42344623210553',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in White Frame': {
            variantId: '42344623243321',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        }
    },
    'Silk': {
        '3R Wallet' : { // verify
            variantId: '42344622194745',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 15,
            minOrder: 20,
            isDoubleLayout: true
        },
        '3R': {
            variantId: '42344622227513',
            width: 3.5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 10,
            minOrder: 20
        },
        '4R': {
            variantId: '42344622260281',
            width: 4,
            height: 6,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 12,
            minOrder: 20
        },
        '5R': {
            variantId: '42344622293049',
            width: 5,
            height: 7,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 20,
            minOrder: 20
        },
        '6R': {
            variantId: '42344622325817',
            width: 6,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 24,
            minOrder: 20
        },
        '12RW (12x18)': {
            variantId: '42344622358585',
            width: 12,
            height: 18,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 230
        },
        '10S (10x10)': {
            variantId: '42344622391353',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 90
        },
        '12R (12x16)': {
            variantId: '42344622424121',
            width: 12,
            height: 16,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 200
        },
        '8R': {
            variantId: '42344622456889',
            width: 8,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 70
        },
        '8S (8x8)': {
            variantId: '42344622489657',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 55
        },
        'Photo Wall Tile 5x5 in Black Frame': {
            variantId: '42344622522425',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in Black Frame': {
            variantId: '42344622555193',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in Black Frame': {
            variantId: '42344622587961',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        },
        'Photo Wall Tile 5x5 in White Frame': {
            variantId: '42344622620729',
            width: 5,
            height: 5,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 264
        },
        'Photo Wall Tile 8x8 in White Frame': {
            variantId: '42344622653497',
            width: 8,
            height: 8,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 371
        },
        'Photo Wall Tile 10x10 in White Frame': {
            variantId: '42344622686265',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 450
        },
    }
}